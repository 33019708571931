var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[(_vm.isEnrolledCounseling)?[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
              name: 'counselingClassesDetail',
              params: { id: _vm.$route.params.id }
            }}},[_c('IsOnlineIcon',{attrs:{"isOnline":_vm.sessionClass.course_session.is_online}}),_vm._v(" "+_vm._s(_vm.sessionClass.title)+" ")],1)],1)]:[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'counseling' }}},[_vm._v(" Counseling ")])],1)],_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":_vm.tasksPageRouter}},[_vm._v(" "+_vm._s(_vm.prevPageTitle)+" ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],2)],2),(_vm.subtask)?_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),(_vm.isEnrolledCounseling)?_c('router-link',{attrs:{"to":{
          name: 'editCounselingSubtasks',
          params: {
            id: _vm.$route.params.id,
            subtaskId: _vm.subtask.id
          }
        }}},[_c('i',{staticClass:"fas fa-edit action-icon"})]):_c('router-link',{attrs:{"to":{
          name: 'editCounselingUserSubtasks',
          params: {
            username: _vm.$route.params.username,
            subtaskId: _vm.subtask.id
          }
        }}},[_c('i',{staticClass:"fas fa-edit action-icon"})]),_c('DeleteIconButton',{attrs:{"onDelete":_vm.deleteSubtask(_vm.subtask.id)}})],1),_c('hr',{staticClass:"separate-line"}),(_vm.subtask)?_c('el-form',{attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Status"}},[_vm._v(" "+_vm._s(_vm.$t(`tasks.${_vm.subtask.progress}`))+" ")]),_c('el-form-item',{attrs:{"label":"Task"}},[_vm._v(" "+_vm._s(_vm.getSubtaskTitle(_vm.subtask.task_id))+" ")]),_c('el-form-item',{attrs:{"label":"Title"}},[_vm._v(" "+_vm._s(_vm.subtask.title)+" ")]),_c('el-form-item',{attrs:{"label":"Description"}},[_vm._v(" "+_vm._s(_vm.subtask.description)+" ")]),_c('el-form-item',{attrs:{"label":"Start Date"}},[_vm._v(" "+_vm._s(_vm.subtask.start_date)+" ")]),_c('el-form-item',{attrs:{"label":"Due Date"}},[_vm._v(" "+_vm._s(_vm.subtask.due_date)+" ")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }