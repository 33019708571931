<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <template v-if="isEnrolledCounseling">
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingClassesDetail',
                params: { id: $route.params.id }
              }"
            >
              <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
              {{ sessionClass.title }}
            </router-link>
          </el-breadcrumb-item>
        </template>
        <template v-else>
          <el-breadcrumb-item>
            <router-link :to="{ name: 'counseling' }">
              Counseling
            </router-link>
          </el-breadcrumb-item>
        </template>
        <el-breadcrumb-item>
          <router-link :to="tasksPageRouter">
            {{ prevPageTitle }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div v-if="subtask">
      <h1>
        {{ pageTitle }}
        <router-link
          v-if="isEnrolledCounseling"
          :to="{
            name: 'editCounselingSubtasks',
            params: {
              id: $route.params.id,
              subtaskId: subtask.id
            }
          }"
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'editCounselingUserSubtasks',
            params: {
              username: $route.params.username,
              subtaskId: subtask.id
            }
          }"
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
        <DeleteIconButton :onDelete="deleteSubtask(subtask.id)" />
      </h1>
      <hr class="separate-line" />
      <el-form v-if="subtask" label-width="100px">
        <el-form-item label="Status">
          {{ $t(`tasks.${subtask.progress}`) }}
        </el-form-item>
        <el-form-item label="Task">
          {{ getSubtaskTitle(subtask.task_id) }}
        </el-form-item>
        <el-form-item label="Title">
          {{ subtask.title }}
        </el-form-item>
        <el-form-item label="Description">
          {{ subtask.description }}
        </el-form-item>
        <el-form-item label="Start Date">
          {{ subtask.start_date }}
        </el-form-item>
        <el-form-item label="Due Date">
          {{ subtask.due_date }}
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import tasksApi from "@/apis/tasks";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    DeleteIconButton
  },
  data() {
    return {
      student: {},
      sessionClass: {
        course_session: {}
      },
      tasks: [],
      subtask: null
    };
  },
  computed: {
    pageTitle() {
      return this.subtask?.title || "";
    },
    prevPageTitle() {
      if (this.student.first_name) {
        return `${this.student.first_name}'s Tasks`;
      } else {
        return "";
      }
    },
    studentUserId() {
      return this.student.id;
    },
    isEnrolledCounseling() {
      return this.$route.params.id;
    },
    tasksPageRouter() {
      if (this.isEnrolledCounseling) {
        return {
          name: "counselingTasks",
          params: { id: this.$route.params.id }
        };
      } else {
        return {
          name: "counselingUserTasks",
          params: { username: this.$route.params.username }
        };
      }
    }
  },
  async created() {
    await this.setLessonAndUser();
    this.setTasks();
    this.setSubtask();
  },
  methods: {
    async setLessonAndUser() {
      if (this.isEnrolledCounseling) {
        await this.fetchCustomLesson();
      } else {
        await this.fetchUserProfile();
      }
    },
    async fetchCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.student = customLesson.student_class_tickets[0].student;
      this.sessionClass = {
        ...customLesson.session_class
      };
    },
    async fetchUserProfile() {
      const {
        basic_info,
        student: { counseling_schools_type }
      } = await profileApi.getUserByName(this.$route.params.username);
      this.student = { ...basic_info, counseling_schools_type };
    },
    async setSubtask() {
      const { sub_task } = await tasksApi.fetchSubtask(
        this.$route.params.subtaskId
      );
      this.subtask = sub_task;
    },
    async setTasks() {
      const { tasks } = await tasksApi.fetchTasks();
      this.tasks = tasks;
    },
    getSubtaskTitle(taskId) {
      return this.tasks.find(({ id }) => taskId === id).title[
        this.$i18n.locale
      ];
    },
    goToTasksPage() {
      this.$router.push(this.tasksPageRouter);
    },
    deleteSubtask(subtaskId) {
      return async () => {
        await tasksApi.deleteSubtask(subtaskId);
        this.goToTasksPage();
      };
    }
  }
};
</script>
